// Copyright 2022 Merit International Inc. All Rights Reserved

import { View } from "react-native";
import React from "react";
import type { TableColumn, TableProps, TableRow as TableRowType } from "./types";

export function TableRow<T extends TableRowType>({
  autoWidth,
  columns,
  render,
  row,
}: {
  readonly autoWidth: number;
  readonly columns: readonly TableColumn[];
  readonly render: TableProps<T>["render"];
  readonly row: T;
}) {
  return (
    <tr
      key={row.id}
      style={{
        borderBottom: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      {columns.map((column, index) => (
        <td
          key={`${row.id}-${column.key}`}
          style={{
            border: 0,
            borderSpacing: 0,
            margin: 0,
            padding: 0,
          }}
        >
          <View
            style={[
              {
                paddingLeft: index === 0 ? 24 : 0,
                paddingRight: index === columns.length ? 24 : 0,
                paddingVertical: 10,
              },
              {
                ...(column.width === "flex"
                  ? {
                      flex: 1,
                    }
                  : {
                      width: column.width === "auto" ? autoWidth : column.width,
                    }),
              },
            ]}
            testID={`${row.id}-${column.key}-TableRow`}
          >
            {render(row, column.key)}
          </View>
        </td>
      ))}
    </tr>
  );
}
