import { Button } from "../Button/Button";
import { Some } from "../../utils/Some";
import { StyleSheet, Text, View } from "react-native";
import { Table } from "../Table/Table.web";
import { formatCurrency } from "../../utils/FormatHelper";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "@merit/frontend-components";
import React, { useMemo } from "react";
import type { GetClaimsResponse } from "../../__generated__/api/ClaimRoute";
import type { MeritCSDrawerRouteParams } from "../../navigation/Routes";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { TableColumn } from "../Table/types";

type TableColumnClaimType = TableColumn & {
  readonly key:
    | "amount"
    | "childName"
    | "id"
    | "schoolYear"
    | "serviceProviderNumber"
    | "status"
    | "view";
};

type Props = {
  readonly claims: GetClaimsResponse["claims"];
};

export const ClaimsList = ({ claims }: Props) => {
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<MeritCSDrawerRouteParams>>();

  const styles = StyleSheet.create({
    bodyText: {
      fontSize: theme.fontSizes.s.fontSize,
      fontWeight: theme.fontWeights.semiBold,
      letterSpacing: 0.5,
      lineHeight: theme.fontSizes.s.lineHeight,
    },
    centeredText: {
      margin: "auto",
    },
    centeredView: {
      alignItems: "center",
      height: "auto",
      justifyContent: "center",
    },
  });

  // Table columns
  const columns: readonly TableColumnClaimType[] = [
    { key: "id", sortable: false, style: styles.bodyText, title: "Claim ID", width: 120 },
    {
      key: "childName",
      sortable: false,
      style: styles.bodyText,
      title: "Student name",
      width: 160,
    },
    { key: "amount", sortable: false, style: styles.bodyText, title: "Claim amount", width: 100 },
    { key: "schoolYear", sortable: false, style: styles.bodyText, title: "Claim year", width: 120 },
    {
      key: "serviceProviderNumber",
      sortable: false,
      style: styles.bodyText,
      title: "Service provider number",
      width: 200,
    },
    { key: "status", sortable: false, style: styles.bodyText, title: "Status", width: 160 },
    {
      key: "view",
      sortable: false,
      style: [styles.bodyText, styles.centeredText],
      title: "Review",
      width: 100,
    },
  ];

  // Table data
  const claimsTableData = useMemo(
    () =>
      claims.map(claim => ({
        amount: formatCurrency(claim.amount),
        childName: `${claim.child.firstName} ${claim.child.lastName}`,
        id: claim.id,
        schoolYear: Some(claim.child.schoolYear)
          ? `${claim.child.schoolYear} - ${claim.child.schoolYear + 1}`
          : "NA",
        serviceProviderNumber: claim.serviceProvider?.number ?? "NA",
        status: claim.status,
        view: (
          <View style={{ paddingRight: 24, width: 120 }}>
            <Button
              customContent={<Text style={{ fontWeight: "600" }}>View</Text>}
              onPress={() => {
                navigation.navigate("MeritCSClaimDetails", { claimId: claim.id });
              }}
              size="small"
              testID={`${claim.id}-ViewButton-ClaimsList`}
              type="secondary"
            />
          </View>
        ),
      })),
    [claims, navigation],
  );

  return (
    <>
      <Table
        columns={columns}
        data={claimsTableData}
        emptyComponent={
          <View style={styles.centeredView}>
            <Text>You have no claims</Text>
          </View>
        }
        render={(data, key) => (
          <Text style={[styles.bodyText, { fontWeight: theme.fontWeights.normal }]}>
            {data[key]}
          </Text>
        )}
      />
    </>
  );
};
